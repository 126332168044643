<template>
  <base-layout title="Dashboard">
    <ion-row>
      <ion-col size="12" class="ion-margin-vertical">
        <div>
          <canvas id="attendance-chart"></canvas>
        </div>
      </ion-col>
      <ion-col size="12" class="ion-margin-vertical" v-if="chaptersInProgress">
        <span v-if="chaptersInProgress.length > 0">
          <ion-item lines="none">
            <ion-label>Chapters in progress</ion-label>
          </ion-item>
          <ion-card v-for="item in chaptersInProgress" :key="item.id">
            <ion-card-header>
              <ion-card-subtitle>
                {{ item.subject.name }} Class: {{ item.subject.standard.name }}, Sections: {{ getSections(item.chapter_progressions) }}
              </ion-card-subtitle>
              <ion-card-title>{{ item.name }}</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              {{ item.description }}
            </ion-card-content>
          </ion-card>
        </span>
        <span v-else>
          <ion-item lines="none">
            <ion-label>No chapters in progress</ion-label>
          </ion-item>
        </span>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

import {
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCardSubtitle,
  IonLabel,
  IonItem,
} from "@ionic/vue";

export default {
  components: {
    BaseLayout,

    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCardSubtitle,
    IonLabel,
    IonItem,
  },

  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },

    attendanceForAssignedClasses() {
      return this.$store.getters["attendance/attendanceForAssignedClasses"];
    },

    chaptersInProgress() {
      return this.$store.getters["chapter/inProgress"];
    },
  },

  methods: {
    async loadAttendanceChart() {
      const user_id = this.user.id;
      await this.$store.dispatch(
        "attendance/attendanceForAssignedClasses",
        user_id
      );

      const loadedData = this.attendanceForAssignedClasses;
      let labels = ["Attendance Breakup"];
      let not_called =
        loadedData.total_students -
        (loadedData.today.present + loadedData.today.absent);
      let present = loadedData.today.present;
      let absent = loadedData.today.absent;

      this.attendanceChartData = {
        data: {
          labels: labels,
          datasets: [
            {
              type: "bar",
              label: "Not Called",
              data: [not_called],

              backgroundColor: "rgb(255, 205, 86, 0.7)",
              borderColor: "rgb(255, 205, 86)",
              borderWidth: 2,
            },
            {
              type: "bar",
              label: "Present",
              data: [present],

              backgroundColor: "rgb(54, 162, 235, 0.7)",
              borderColor: "rgb(54, 162, 235)",
              borderWidth: 2,
            },
            {
              type: "bar",
              label: "Absent",
              data: [absent],

              backgroundColor: "rgb(255, 99, 132, 0.7)",
              borderColor: "rgb(255, 99, 132)",
              borderWidth: 2,
            },
          ],
        },

        options: {
          responsive: true,
          scales: {
            x: {
              ticks: {
                display: false,
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: "Daily Attendance for assigned classes",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      };
    },

    getSections(progressions) {
      let sections = "";
      progressions.forEach((progression) => {
        sections += `${progression.section.name}, `;
      });

      return sections;
    },

    async initAttendanceChart() {
      await this.loadAttendanceChart();
      const ctx = document.getElementById("attendance-chart");
      new Chart(ctx, this.attendanceChartData);
    },

    async loadChaptersInProgress() {
      const user_id = this.user.id;
      await this.$store.dispatch("chapter/inProgress", user_id);
    },
  },

  created() {
    this.initAttendanceChart();
    this.loadChaptersInProgress();
  },
};
</script>

<style scoped>
</style>
